<template>
  <div :id="id" class="progress-bar-circular">
    <div class="progress-bar-graph" :style="{ width: size, height: size }">
      <svg :style="{ width: size, height: size }">
        <circle
          :style="{
            stroke: 'var(--bg-mid-medium)',
          }"
          :cx="size / 2.5"
          :cy="size / 2.55"
          :r="size / 2.55"
        />
        <circle
          :style="{
            'stroke-dashoffset': `calc(${dashArrayLength} - (${dashArrayLength} * ${percentualValue} / 100))`,
            'stroke-dasharray': dashArrayLength,
            stroke: color,
          }"
          :cx="size / 2.5"
          :cy="size / 2.55"
          :r="size / 2.55"
        />
      </svg>
    </div>
    <div class="progress-text">
      <h3 v-if="!showPercentage && title" :style="{ color }">{{ title }}</h3>
      <h3 v-if="showPercentage" :style="{ color }">{{ percentualValue }}%</h3>
      <h4 v-if="text">{{ text }}</h4>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';

export default {
  props: {
    id: {
      type: String,
      default: `progress-circular-${uuid()}`,
    },
    value: {
      required: true,
      type: Number,
    },
    totalValue: {
      required: true,
      type: Number,
      default: 100,
    },
    title: {
      default: '',
      type: String,
    },
    text: {
      default: '',
      type: String,
    },
    color: {
      default: 'var(--color-primary)',
      type: String,
    },
    size: {
      default: 100,
      type: Number,
    },
    showPercentage: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      dashArrayLength: 0,
    };
  },
  computed: {
    percentualValue() {
      return ((this.value * 100) / this.totalValue).toFixed(1);
    },
  },
  watch: {
    size: {
      handle() {
        this.$$nextTick(() => {
          this.dashArrayLength = this.getTotalLength();
        });
      },
    },
  },
  mounted() {
    this.dashArrayLength = this.getTotalLength();
  },
  methods: {
    getTotalLength() {
      return (
        document
          .querySelector(`#${this.id} svg > circle:last-child`)
          .getTotalLength() - 3
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar-circular {
  display: inline-block;
  position: relative;

  .progress-bar-graph {
    width: 100px;
    height: 100px;
    transform: rotate(-90deg);

    svg {
      position: relative;

      circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke-width: 10;
        stroke-linecap: round;
        transform: translate(10px, 10px);
      }
    }
  }

  .progress-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    text-align: center;
    user-select: none;

    h3,
    h4 {
      margin: 0;
    }

    h3 {
      top: calc(50% - 12px);
      font-size: 14px;
      line-height: 20px;
      color: var(--color-primary);
    }

    h4 {
      top: calc(50% + 8px);
      font-size: 7px;
      line-height: 8px;
      color: var(--text-dark);
      font-weight: 500;
    }
  }
}
</style>
