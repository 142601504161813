<template>
  <div
    v-if="site.licenseidentifier"
    class="site-license-code"
    @click="copyLicenseCode"
  >
    <div>
      <span class="text-truncate">{{ site.licenseidentifier }}</span>

      <Icon
        family="fas"
        :name="isCopyingLicenseCode ? 'check' : 'copy'"
        class="ml-2"
      />
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon.vue';

export default {
  name: 'SiteLicenseIdentifier',
  components: { Icon },
  data() {
    return {
      isCopyingLicenseCode: false,
    };
  },
  computed: {
    site() {
      if (this.$route.params && this.$route.params.trialType) {
        return this.$store.getters['sites/getTrial'](
          this.$route.params.trialType
        );
      } else if (this.$route.params && this.$route.params.site) {
        return this.$store.getters['sites/getLicense'](this.$route.params.site);
      } else {
        return {};
      }
    },
  },
  methods: {
    async copyLicenseCode() {
      this.isCopyingLicenseCode = true;
      const type = 'text/plain';
      const blob = new Blob([this.site.licenseidentifier], { type });
      const data = [new window.ClipboardItem({ [type]: blob })];

      await navigator.clipboard.write(data);

      setTimeout(() => {
        this.isCopyingLicenseCode = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.site-license-code {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  color: var(--text-light);
  font-family: var(--font-mono);

  > div {
    display: flex;
    align-items: center;

    &:hover {
      color: var(--color-primary);
      cursor: pointer;
    }
  }
}
</style>
