<template>
  <div
    v-if="licenseIdentifier"
    class="site-license-code py-2"
    @click="copyLicenseCode"
  >
    <h3 v-if="!hideLabel" class="mb-1">{{ $t('License Key') }}</h3>

    <div>
      <span class="text-truncate">{{ licenseIdentifier }}</span>

      <Icon
        family="fas"
        :name="isCopyingLicenseCode ? 'check' : 'copy'"
        class="ml-2"
      />
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon.vue';

export default {
  name: 'SiteLicenseIdentifier',
  components: { Icon },
  props: {
    licenseIdentifier: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCopyingLicenseCode: false,
    };
  },
  methods: {
    async copyLicenseCode() {
      this.isCopyingLicenseCode = true;
      const type = 'text/plain';
      const blob = new Blob([this.licenseIdentifier], { type });
      const data = [new window.ClipboardItem({ [type]: blob })];

      await navigator.clipboard.write(data);

      setTimeout(() => {
        this.isCopyingLicenseCode = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.site-license-code {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  color: var(--text-light);
  font-family: var(--font-mono);

  h3 {
    font-size: 12.8px;
    color: var(--text-dark);
  }

  > div {
    display: flex;
    align-items: center;

    &:hover {
      color: var(--color-primary);
      cursor: pointer;
    }
  }
}
</style>
