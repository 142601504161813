<template>
  <div class="trial-server-license">
    <div class="trial-server-license__row">
      <LicensePaper />
      <p>
        {{
          $t(
            'You will need to download your license XML file to activate your FileCloud installation'
          )
        }}
      </p>
    </div>

    <a :href="licenseUrl" target="_blank" class="btn btn-primary">
      {{ $t('Download product license file (XML)') }}
    </a>
  </div>
</template>

<script>
import LicensePaper from '@/assets/flat-license-paper.svg';

export default {
  name: 'DownloadTrialServerLicense',
  components: {
    LicensePaper,
  },
  props: {
    licenseUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.trial-server-license {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 350px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 1rem;
    font-size: 14.4px;

    svg {
      min-width: 52px;
    }

    p {
      min-width: 235px;
      color: var(--color-primary--foreground);
      font-weight: 600;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    text-wrap: nowrap;
  }
}
</style>
