<template>
  <div class="server-trial-support">
    <div class="bar">
      <div class="content">
        <div class="text">
          <span>{{ $t('Get the support you need') }}</span>
        </div>
        —
        <v-popover>
          <a class="link">
            {{ $t('contact us for assistance!') }}
          </a>

          <template #popover>
            <div class="contact-us-popover">
              <div class="icon-col">
                <WarningIcon />
              </div>
              <div class="content-col">
                <p>
                  {{
                    $t(
                      'You will need this License key to open a support ticket:'
                    )
                  }}
                </p>

                <div class="license-key">
                  <span>{{ $t('License key') }}:</span>
                  <SiteLicenseCopy />
                </div>

                <a
                  href="https://help.filecloud.com/support"
                  target="_blank"
                  class="btn btn-primary btn-sm"
                >
                  {{ $t('Open a Support Ticket') }}
                </a>
              </div>
            </div>
          </template>
        </v-popover>
      </div>
    </div>

    <div class="spacement"></div>
  </div>
</template>

<script>
import WarningIcon from '@/assets/warning.svg';
import SiteLicenseCopy from '@/components/SiteLicenseCopy.vue';

export default {
  name: 'ServerTrialSupportBar',
  components: {
    WarningIcon,
    SiteLicenseCopy,
  },
};
</script>

<style lang="scss" scoped>
$bar-height: 65px;

.contact-us-popover {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 26px 36px;
  background-color: var(--bg-light);

  .content-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      font-size: 14px;
      text-align: left;
      font-weight: 500;
      margin: 0;
    }

    .license-key {
      padding-block: 10px 25px;

      span {
        display: block;
        text-align: left;
        color: var(--text-dark);
        font-weight: 700;
        font-size: 14px;
        margin: 0;
      }

      p {
        font-size: 14px;
        text-align: left;
        font-weight: 600;
        margin: 0;
      }
    }

    .btn {
    }
  }
}

.server-trial-support {
  .bar {
    position: absolute;
    left: 0;
    right: 0;
    height: $bar-height;
    background-color: #fbfafb;
    border-block: 1px solid var(--border-color);

    .content {
      display: flex;
      gap: 2px;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0 20px;

      .text {
        font-size: 14px;
        color: var(--text-color);
      }

      .link {
        border-radius: 5px;
        color: var(--color-primary);
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .spacement {
    height: $bar-height;
  }
}
</style>
