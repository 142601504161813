<template>
  <ul class="trial-server-actions">
    <li
      v-for="(action, index) in actions"
      :key="index"
      class="trial-server-actions__item"
    >
      <component :is="action.icon" />

      <h3 class="trial-server-actions__item__title">{{ action.title }}</h3>

      <p class="trial-server-actions__item__description">
        {{ action.description }}
      </p>

      <a
        class="btn btn-primary trial-server-actions__item__link"
        :href="action.link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ action.linkLabel }}
      </a>
    </li>
  </ul>
</template>

<script>
import WatchPlay from '@/assets/watch-play.svg';
import MoreHelpBoard from '@/assets/more-help-board.svg';
import SearchDocs from '@/assets/search-docs.svg';

export default {
  name: 'ServerTrialActions',
  data() {
    return {
      actions: [
        {
          icon: WatchPlay,
          title: 'Watch demo',
          description: 'Explore our product in action',
          link: 'https://www.filecloud.com/filecloud-tutorial-videos/',
          linkLabel: 'Watch',
        },
        {
          icon: SearchDocs,
          title: 'Read documentation',
          description: 'Get all the details you need',
          link: 'https://www.filecloud.com/supportdocs/',
          linkLabel: 'Explore',
        },
        {
          icon: MoreHelpBoard,
          title: 'Need More Help?',
          description: 'Schedule a guided POC',
          link: 'https://www.filecloud.com/filecloud-request-free-demo/',
          linkLabel: 'Schedule',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.trial-server-actions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 48px;
  max-width: 823px;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 0 0 3.75rem;

  &__item {
    display: grid;
    justify-items: center;
    align-content: center;
    grid-template-rows: 60px 30px 50px 1fr;
    border-radius: 0.5rem;
    text-align: center;

    &__title {
      font-size: 16px;
      font-weight: 600;
      color: var(--color-primary);
      margin: 0;
    }

    &__description {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 14px;
      text-decoration: none;
      height: 28px;
      width: 100%;
      max-width: 140px;
    }
  }
}
</style>
